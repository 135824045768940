import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { AccountInfoService, AlertsService, AuthDataModel, SessionInfoService } from "degarmo-lib";

@Injectable({
    providedIn: 'root'
})
export class UserSecurityGuard  {
    constructor(
        private sessionInfoService: SessionInfoService,
        private accountInfoService: AccountInfoService,
        private alertsService: AlertsService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const allowedUserGroups: string[] = route.data.allowedUserGroups as Array<string>;
        const allowedSecurityLevelIDs: number[] = route.data.allowedSecurityLevelIDs as Array<number>;
        const allowedCompanyIDs: number[] = route.data.allowedCompanyIDs as Array<number>;
        const authenticate: boolean = route.data.authenticate as boolean;
        const auth: AuthDataModel = this.sessionInfoService.getAuthenticationInformation();
        const userLoginInfo = this.accountInfoService.getUserLoginInfo();

        let approved = false;

        if (authenticate) {
            // const menuItem: MenuItem = this.userRouteMenuService.getMenuItemWrapper(state.url);
            
            if (userLoginInfo) {
                const userType = userLoginInfo.userType;
                const securityLevelID = userLoginInfo.securityLevelID;
    
                if (!allowedUserGroups || (allowedUserGroups && !allowedUserGroups.length))
                    approved = true;
                else if (allowedUserGroups && allowedUserGroups.length > 0) { 
                    // check for allowedUserGroups
                    for (const group of allowedUserGroups) {
                        if (group === userType) {
                            approved = true;
                            break;
                        }
                    }
                }
    
                // check for allowedSecurityLevelIDs
                if (approved && allowedSecurityLevelIDs && userLoginInfo.isProctor) {
                    approved = allowedSecurityLevelIDs.indexOf(securityLevelID) !== -1;
                }
    
                /* Temp fix to hide routes from certain companies */
                if (approved && allowedCompanyIDs && userLoginInfo.isProctor) {
                    const companyID = userLoginInfo.companyID;
                    approved = allowedCompanyIDs.indexOf(companyID) !== -1;
                }
            }
        } else {
            approved = true;
        }

        if (!approved) {
            this.alertsService.error('You are not permitted to view that resource', 'Permissions Error');
            let prevURL: string = '';

            if (auth && auth.isLoggedIn)
                prevURL = '';
            else 
                prevURL = '/account/login';
            
            this.router.navigate([prevURL]);
        }

        return approved;
    }
}